import React from "react"
import App from "./components/Main";
// import { GlobalStyle } from "../theme/global-style";

const IndexPage = () => (
<>
{/* <GlobalStyle /> */}
<App />
</>
)

export default IndexPage
